import config from "../config";
import { getToken } from "./storage";
import { getRight } from '../services/storage';


const getRequestHeaders = () => {
    try {
        return {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-Access-Token': getToken(),
            'Office-Version': config.version
        }
    } catch (error) {
        return null;
    }
}

const getQueryStringFromObject = (obj) => {
    let queryString = '';
    let queryStringArray = [];
    for (const [key, value] of Object.entries(obj)) {
        queryStringArray.push(`${key}=${value}`);
    }
    queryString = queryStringArray.join('&');
    return queryString;
}

const getRoleFromRight = (right) => {
    let permissions = config.permissions;
    let roles = Object.values(permissions);
    let role = roles.find((role) => role.value === right);
    return role;
}

const getRoles = () => {
    let right = getRight();
    let permissions = config.permissions;
    let roles = Object.values(permissions);
    roles = roles.filter((role) => role.value <= right);
    return roles;
}

const getDeviceType = () => {
    let deviceType = 'desktop';
    if (window.innerWidth < 768) {
        deviceType = 'mobile';
    }
    return deviceType;
}

export {
    getRequestHeaders,
    getQueryStringFromObject,
    getRoleFromRight,
    getRoles,
    getDeviceType
}
