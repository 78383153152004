import react, { useState } from 'react';
import V2Layout from './Layout';
import { Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, InputLabel, LinearProgress, MenuItem, Paper, Select, Stack, TextField } from '@mui/material';
import config from '../../config';
import { translate } from '../../i18n/customI18nProvider';
import { getDeviceList } from '../../services/device.service';
import Swal from 'sweetalert2';
import { convertMessageCodeToMessage } from '../../util';
import { createUser, forceCreateUser, forceSaveUserDetails, getUserDetails, saveUserDetails, validateSaveUserDetails } from '../../services/user.service';
import { headerTextStyles } from '../../styles/common.style';
import { getRoles } from '../../services/common.service';
import { getEventList } from '../../services/event.service';
import { getBarDetails, getBarList } from '../../services/bar.service';
import { getRight } from '../../services/storage';


const CopySecureLoginLink = ({ token }) => {

    const [copied, setCopied] = useState(false);

    return (
        <Button
            variant="outlined"
            color="primary"
            onClick={() => {
                const link = `${window.location.origin}/login?token=${token}`
                navigator.clipboard.writeText(link)
                setCopied(true)
                setTimeout(() => {
                    setCopied(false)
                }, 2000);
            }}
            disabled={copied}
        >
            {copied ? translate('custom.copied') : translate('custom.copy_secure_login_link')}
        </Button>
    )
}

class AddEditUser extends react.Component {

    constructor(props) {
        super(props);
        this.state = {
            current_right: getRight(),
            events: [],
            bars: [],
            roles: [],
            devices: [],
            title: translate('custom.users'),
            loading: false,
            form_error: {},
            // ------
            email: '',
            password: '',
            event: null,
            bar: null,
            right: null,
            token: null,
            smartphones: [],
            force: false
        }
    }

    async setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    async componentDidMount() {
        // get the mode, if /add, then create a new user
        // if /:user_id, then fetch the user details
        let mode = this.props?.match?.params?.user_id ? 'edit' : 'add';
        let user_id = this.props?.match?.params?.user_id ? this.props.match.params.user_id : null;
        await this.setState({
            loading: true,
            mode,
            user_id,
            title: mode === 'add' ? translate('custom.add_user') : translate('custom.edit_user')
        });

        let promises = [];
        promises.push(this.setDevices());
        promises.push(this.setEvents());
        promises.push(this.setBars());
        promises.push(this.setRoles());

        // resolve all promises
        await Promise.all(promises);

        if (mode === 'edit')
            await this.getUser();
            
        this.setState({ loading: false });
    }   

    async getUser() {
        // if user_id, fetch user details
        let user = await getUserDetails(this.state.user_id);
        if (user.error) {
            Swal.fire({
                icon: 'error',
                title: translate('custom.error'),
                text: user.error_code ? convertMessageCodeToMessage(user.error_code) : user.error
            });
            return;
        }
        user = user.user;

        this.setState({
            email: user.email ? user.email : '',
            right: user.right ? user.right : null,
            token: user.token ? user.token : null,
            smartphones: user.smartphones ? user.smartphones.map((smartphone) => { return({id: smartphone, label: smartphone}) }) : []
        });

        let event_id = user?.event_id?._id ? user?.event_id?._id : user?.event_id;
        if (event_id) {
            let event = this.state.events.find((event) => event.id === event_id);
            this.setState({ event });
        }

        let bar_id = user?.bar_id?._id ? user?.bar_id?._id : user?.bar_id;
        if (bar_id) {
            let bar = this.state.bars.find((bar) => bar.id === bar_id);
            this.setState({ bar });
        }

        let right_value = user.right;
        let right = getRoles().find((role) => role.value === right_value);
        this.setState({ right });
    }

    async setDevices() {
        let devices = await getDeviceList({
            minimal: true,
            type: config.device_types.SMARTPHONE.key
        });

        if (devices.error) {
            Swal.fire({
                icon: 'error',
                title: translate('custom.error'),
                text: devices.error_code ? convertMessageCodeToMessage(devices.error_code) : devices.error
            });
            return;
        }

        let devicesArray = devices.devices.map((device) => {
            return {
                id: device.sl_no,
                label: device.sl_no
            }
        });

        this.setState({ devices: devicesArray });
    }

    async setEvents() {
        let events = await getEventList({
            minimal: true
        });

        if (events.error) {
            Swal.fire({
                icon: 'error',
                title: translate('custom.error'),
                text: events.error_code ? convertMessageCodeToMessage(events.error_code) : events.error
            });
            return;
        }

        let eventsArray = events.events.map((event) => {
            return {
                id: event._id,
                label: event.name
            }
        });

        await this.setStateAsync({ events: eventsArray });
    }

    async setEventFromBar() {
        
        if (!this.state.bar) return;

        let bar = await getBarDetails(this.state.bar.id);
        
        if (bar.error) {
            Swal.fire({
                icon: 'error',
                title: translate('custom.error'),
                text: bar.error_code ? convertMessageCodeToMessage(bar.error_code) : bar.error
            });
            return;
        }

        let event_id = bar.bar.event_id;
        let event = this.state.events.find((event) => event.id === event_id);
        this.setState({ event });

    }

    async setBars() {

        let filter = {
            minimal: true
        }

        if (this.state.event) {
            filter['event_id'] = this.state.event.id;
        }

        let bars = await getBarList(filter);

        if (bars.error) {
            Swal.fire({
                icon: 'error',
                title: translate('custom.error'),
                text: bars.error_code ? convertMessageCodeToMessage(bars.error_code) : bars.error
            });
            return;
        }

        let barsArray = bars.bars.map((bar) => {
            return {
                id: bar._id,
                label: bar.name
            }
        });

        await this.setStateAsync({ bars: barsArray });
    }

    async setRoles() {
        let roles = getRoles();
        await this.setStateAsync({ roles });
    }

    async save(save_and_add=false) {

        if (!this.isFormValid()) return;

        if (!this.state.force && !await this.validateUserForm()) return;

        let payload = {
            email: this.state.email,
            bar_id: this.state.bar.id,
            right: this.state.right.value,
        }

        if (this.state.password !== '') {
            payload['password'] = this.state.password;
        }

        if (this.state.current_right >= config.permissions.SUPERADMIN.value) {
            payload['smartphones'] = this.state.smartphones.map((smartphone) => smartphone.id);
        }

        let response;
        if (this.state.mode === 'add') {
            if (this.state.force)
                response = await forceCreateUser(payload);
            else
                response = await createUser(payload);
        } else if (this.state.mode === 'edit') {
            if (this.state.force)
                response = await forceSaveUserDetails(this.state.user_id, payload);
            else
                response = await saveUserDetails(this.state.user_id, payload);
        }

        if (response.error) {
            Swal.fire({
                icon: 'error',
                title: translate('custom.error'),
                text: response.error_code ? convertMessageCodeToMessage(response.error_code) : response.error
            });
            return;
        }

        if (save_and_add) {
            // reset form
            this.resetForm();
        } else {
            this.props.history.push(`/v2/admin/users`);
        }

        Swal.fire({
            icon: 'success',
            title: translate('custom.success'),
            text: translate('custom.saved_successfully'),
            showConfirmButton: false,
            timer: 1500
        });


    }

    resetForm() {
        this.setState({
            email: '',
            password: '',
            event: null,
            bar: null,
            right: null,
            smartphones: []
        });
    }

    isFormValid(key=null) {

        let form_error = this.state.form_error;

        if (!key || key == 'email') {
            if (this.state.email === '' || !this.state.email) {
                form_error['email'] = translate('custom.required');
            } else {
                delete form_error['email'];
            }
        }
        
        if (!key || key == 'password') {
            if ((this.state.mode == 'add') && (this.state.password === '' || !this.state.password)) {
                form_error['password'] = translate('custom.required');
            } else {
                delete form_error['password'];
            }
        }

        if (!key || key == 'event') {
            if (!this.state.event) {
                form_error['event'] = translate('custom.required');
            } else {
                delete form_error['event'];
            }
        }
        
        if (!key || key == 'bar') {
            if (!this.state.bar) {
                form_error['bar'] = translate('custom.required');
            } else {
                delete form_error['bar'];
            }
        }
        
        if (!key || key == 'right') {
            if (!this.state.right) {
                form_error['right'] = translate('custom.required');
            } else {
                delete form_error['right'];
            }
        }

        if (!key || key == 'smartphones') {
            delete form_error['smartphones'];
        }

        if (Object.keys(form_error).length > 0) {
            this.setState({ form_error });
            return false;
        } else {
            this.setState({ form_error: {} });
            return true;
        }

    }

    validateUserForm = async () => {
        let form_error = this.state.form_error;
    
    
        let payload = {
            "email" : this.state.email,
            "smartphones" : this.state.smartphones.map((smartphone) => smartphone.id)
        }
        
        if (this.state.user_id) {
            payload["_id"] = this.state.user_id;
        }
    
        let user_validate = await validateSaveUserDetails(payload);
    
        if (user_validate?.email) {
            form_error['email'] = convertMessageCodeToMessage(user_validate?.email)
        }
        
        if (user_validate?.smartphones) {
            if (user_validate.smartphones?.length > 0) {
                form_error['smartphones'] = '';
                for (let i=0; i<user_validate.smartphones.length; i++) {
                    if (form_error['smartphones'] != '') {
                        form_error['smartphones'] += ' & ';
                    }
                    form_error['smartphones'] += translate('custom.smartphone') + ' - ' + user_validate.smartphones[i].smartphone_number + ' ' + translate('custom.is_already_taken_by') + ' ' + user_validate.smartphones[i].email + ' @ ' + user_validate.smartphones[i].event_name;
                }
            }
        }

        this.setState({ form_error });

        if (Object.keys(form_error).length > 0) {
            return false;
        }

        return true;
    }

    render() {
        return (
            <V2Layout
                currentMenu='users'
                title={this.state.title}
            >

                {(this.state.loading) ? (
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                ) : null}

                <Paper sx={{mt: 2, padding: 2}}>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <h3 style={headerTextStyles}>{translate('custom.identifiers')}</h3>
                        </Grid>

                        <Grid item xs={12} md={4} lg={4} xl={4}>
                            <TextField fullWidth label={translate('custom.username')} variant='outlined'
                                value={this.state.email}
                                onChange={async (e) => {
                                    await this.setState({ email: e.target.value })
                                    await this.isFormValid('email');
                                }}
                                error={this.state.form_error['email'] ? true : false}
                                helperText={this.state.form_error['email']}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={4} xl={4}>
                            <TextField fullWidth label={translate('custom.password')} variant='outlined'
                                value={this.state.password}
                                onChange={async (e) => {
                                    await this.setState({ password: e.target.value })
                                    await this.isFormValid('password');
                                }}
                                error={this.state.form_error['password'] ? true : false}
                                helperText={this.state.form_error['password']}
                            />
                        </Grid>



                        <Grid item xs={12}>
                            <h3 style={headerTextStyles}>{translate('custom.configuration')}</h3>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} xl={3}>
                            <Autocomplete
                                disabled={this.state.mode === 'edit'}
                                disablePortal
                                id="event-label"
                                options={this.state.events}
                                value={this.state.event}
                                renderInput={(params) => <TextField {...params} label={translate('custom.event')} />}
                                getOptionKey={(option) => option.id}
                                onChange={async (e, value) => {
                                    await this.setStateAsync({ event: value, bar: null });
                                    this.setBars();
                                }}
                            />
                        </Grid>
                        
                        <Grid item xs={12} md={6} lg={4} xl={3}>
                            <Autocomplete
                                disablePortal
                                id="bar-label"
                                options={this.state.bars}
                                value={this.state.bar}
                                renderInput={(params) => 
                                    <TextField
                                        {...params}
                                        label={translate('custom.point_of_sale')}
                                        error={this.state.form_error['bar'] ? true : false}
                                        helperText={this.state.form_error['bar']}
                                    />
                                }
                                getOptionKey={(option) => option.id}
                                onChange={async (e, value) => {
                                    await this.setStateAsync({ bar: value });
                                    this.setEventFromBar();
                                    await this.isFormValid('bar');
                                }}
                            />
                        </Grid>
                        
                        <Grid item xs={12} md={6} lg={4} xl={3}>

                            <Autocomplete
                                disablePortal
                                id="right-label"
                                options={this.state.roles}
                                value={this.state.right}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label={translate('custom.permission')}
                                        error={this.state.form_error['right'] ? true : false}
                                        helperText={this.state.form_error['right']}
                                    />
                                }
                                getOptionKey={(option) => option.value}
                                getOptionLabel={(option) => option.name ? option.name : ''}
                                onChange={async (e, value) => {
                                    await this.setStateAsync({ right: value });
                                    await this.isFormValid('right');
                                }}
                            />
                        </Grid>

                        {(this.state.token) ? (
                            <Grid item xs={12}>
                                <CopySecureLoginLink token={this.state.token} />
                            </Grid>
                        ) : null}




                        <Grid item xs={12}>
                            <h3 style={headerTextStyles}>{translate('custom.smartphones')}</h3>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12} xl={12}>
                            <Autocomplete
                                // disablePortal
                                disableCloseOnSelect
                                multiple
                                freeSolo
                                id="smartphones-label"
                                options={this.state.devices}
                                value={this.state.smartphones}
                                renderInput={(params) => 
                                    <TextField
                                        {...params}
                                        label={translate('custom.smartphone_number')}
                                        error={this.state.form_error['smartphones'] ? true : false}
                                        helperText={this.state.form_error['smartphones']}
                                    />
                                }
                                getOptionKey={(option) => option.id}
                                getOptionLabel={(option) => String(option.label)}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                onChange={async (e, value) => {
                                    let multiple_smartphones_input = [];
                                    for (let i=0; i<value.length; i++) {
                                        if (typeof(value[i]) === 'string') {
                                            let split = value[i].split(' ');
                                            split = split.filter((s) => s !== '');
                                            multiple_smartphones_input = multiple_smartphones_input.concat(split);
                                        }
                                    }

                                    value = value.filter((v) => typeof(v) !== 'string');
                                    value = value.concat(multiple_smartphones_input.map((smartphone) => { return { id: smartphone, label: smartphone } }));

                                    // remove duplicates
                                    value = value.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
                                    // update devices if new ones are added
                                    let devices = this.state.devices;
                                    for (let i=0; i<value.length; i++) {
                                        if (!devices.find((device) => device.id === value[i].id)) {
                                            devices.push({ id: value[i].id, label: value[i].id });
                                        }
                                    }

                                    await this.setState({ smartphones: value, devices });
                                    await this.isFormValid('smartphones');
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{mt: 2, justifyContent: 'space-around'}}>
                        <Grid item xs={12}>
                            <Stack direction='row' spacing={2}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.force}
                                                onChange={(e) => {
                                                    this.setState({ force: e.target.checked, form_error: {} });
                                                }}
                                            />
                                        }
                                        label={translate('custom.force')}
                                    />
                                </FormGroup>
                                <Button variant='contained' color='primary'
                                    onClick={async () => {
                                        await this.save();
                                    }}
                                    disabled={this.state.loading}
                                >
                                    {translate('custom.save')}
                                </Button>
                                <Button variant='outlined' color='primary'
                                    onClick={async () => {
                                        await this.save(true);
                                    }}
                                    disabled={this.state.loading}
                                >
                                    {translate('custom.save_and_add')}
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Paper>
            </V2Layout>
        );
    }
}

export default AddEditUser;
